import axios from "axios";
import {
	LOGIN_FAILURE,
	LOGIN_SUCCESS,
	LOG_OUT,
	REGISTER_FAILURE,
	REGISTER_SUCCESS,
	SET_LOGIN_LOADING,
	SET_REGISTER_LOADING,
	CLEAR_ERRORS,
	CLEAR_REGISTER_SUCCESS,
	CLEAR_LOGIN_SUCCESS,
	GET_USER_SUCCESS,
	GET_USER_FAILURE,
	GET_USER_LOADING,
} from "../types/auth";
import { baseUrl } from "../../utils/constants";
import setAuthToken from "../../utils/setAuthToken";

export const registerAction = (user) => async (dispatch) => {
	dispatch({
		type: SET_REGISTER_LOADING,
	});
	try {
		const response = await axios.post(`${baseUrl}/api/users`, user);
		console.log(response);
		dispatch({
			type: REGISTER_SUCCESS,
			payload: response.data,
		});
	} catch (err) {
		localStorage.removeItem("token");
		console.log(err);
		if (err.response) {
			if (err.response.status) {
				const status = err.response.status;
				if (/^4/.test(status)) {
					dispatch({
						type: REGISTER_FAILURE,
						payload: err.response.data.message,
					});
				} else if (/^5/.test(status)) {
					dispatch({
						type: REGISTER_FAILURE,
						payload: " Something went wrong. Please try again",
					});
				}
			} else {
				dispatch({
					type: REGISTER_FAILURE,
					payload: "Something went wrong. Please try again.",
				});
			}
		} else {
			dispatch({
				type: REGISTER_FAILURE,
				payload: "Something went wrong. Please try again.",
			});
		}
	}
};

export const loginAction = (user) => async (dispatch) => {
	dispatch({
		type: SET_LOGIN_LOADING,
	});
	try {
		const response = await axios.post(`${baseUrl}/auth/local`, user);
		const token = response.data.token;
		localStorage.setItem("token", token);
		dispatch({
			type: LOGIN_SUCCESS,
			payload: token,
		});
	} catch (err) {
		localStorage.removeItem("token");
		console.log(err);
		if (err.response) {
			if (err.response.status) {
				const status = err.response.status;
				if (/^4/.test(status)) {
					dispatch({
						type: LOGIN_FAILURE,
						payload: err.response.data.message,
					});
				} else if (/^5/.test(status)) {
					dispatch({
						type: LOGIN_FAILURE,
						payload: " Something went wrong. Please try again",
					});
				}
			} else {
				dispatch({
					type: LOGIN_FAILURE,
					payload: "Something went wrong. Please try again.",
				});
			}
		} else {
			dispatch({
				type: LOGIN_FAILURE,
				payload: "Something went wrong. Please try again.",
			});
		}
	}
};

export const getUser = () => async (dispatch) => {
	dispatch({
		type: GET_USER_LOADING,
	});
	try {
		if (localStorage.token) {
			setAuthToken(localStorage.token);
		}
		const response = await axios.get(`${baseUrl}/api/users/me`);
		dispatch({
			type: GET_USER_SUCCESS,
			payload: response.data,
		});
	} catch (err) {
		localStorage.removeItem("token");
		dispatch({
			type: GET_USER_FAILURE,
		});
	}
};

export const logout = () => (dispatch) => {
	localStorage.removeItem("token");
	dispatch({
		type: LOG_OUT,
	});
};

// helpers
export const clearErrors = (time = 3000) => (dispatch) => {
	setTimeout(() => {
		dispatch({
			type: CLEAR_ERRORS,
		});
	}, time);
};

export const clearRegisterSuccess = () => (dispatch) => {
	dispatch({
		type: CLEAR_REGISTER_SUCCESS,
	});
};

export const clearLoginSuccess = () => (dispatch) => {
	dispatch({
		type: CLEAR_LOGIN_SUCCESS,
	});
};
