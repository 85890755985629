import { DUMMY } from "../types/dummy";

const initialState = {
	name: "GodLives",
	location: "Sweden",
};

const dummyReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case DUMMY:
			return {
				...state,
				name: "GodLived Delivery" + payload,
			};
		default:
			return state;
	}
};

export default dummyReducer;
