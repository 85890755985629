import React from "react";
import { Helmet } from "react-helmet";
import { Row, Col, Container } from "react-bootstrap";

const TermsAndConditions = () => {
	return (
		<div>
			<section className="pt-5 pb-5 ">
				<Helmet>
					<title>GodLives Delivery | Terms and Conditions</title>
				</Helmet>
				<Container>
					<Row>
						<Col>
							<h1 className="mb-5 font-weight-bold text-center ">
								Terms and Conditions
							</h1>

							<div className="mb-4" >
								<h3>INTRODUCTION</h3>
								<p>
									<span className="font-weight-bold">
										This document, together with the other references herewith
										made spells out the terms
									</span>{" "}
									(the <span className="font-weight-bold">“Terms”</span>) which
									apply between you as a customer (the{" "}
									<span className="font-weight-bold">“customer”, “you”</span>)
									and Godlives Delivery when you order any food, drinks, and
									other products (the “Products“) from our{" "}
									<a
										target="_blank"
										rel="noopener noreferrer"
										href="https://godlivesdelivery.se/"
									>
										godlivesdelivery.se
									</a>{" "}
									website or mobile application or platform (each referred to as
									an “Application”, “App”).
								</p>

								<p>
									Please read these Terms carefully before creating an account
									or using our Apps. If you have any questions relating to these
									Terms please contact{" "}
									<a href="mailto:info@godlivesdelivery.se">
										info@godlivesdelivery.se
									</a>{" "}
									. By creating an account with Godlives or by using the Apps
									and/or Services specified below you confirm that you have read
									and accept these Terms.
								</p>
							</div>

							<div className="mb-4" >
								<h3>1. Information About Us</h3>
								<p>
									Godlivesdelivery.se is a food delivery company registered and
									operating in Sweden from 2018 with registered organisation and
									VAT numbers 790821-1555 and SE790821155501 respectively. The
									company’s operations conform to food safety guidelines issued
									by the National Food Agency (Sw. Livsmedelsverket) and are
									also registered with the Environmental Control Department in
									Huddinge (Sw. Miljötillsynsavdelningen). Godlives is therefore
									certified to handle and deliver food to the general public and
									also regulated by the Swedish authorities.
								</p>

								<p>
									The company’s address is Alfred Nobel’s Alle 27, 14152
									Huddinge. You can contact us through our website or by sending
									an email to{" "}
									<a href="mailto:info@godlivesdelivery.se">
										info@godlivesdelivery.se
									</a>{" "}
									.
								</p>
							</div>

							<div className="mb-4" >
								<h3>2. Purpose</h3>
								<p>
									The primary aim of Godlives Delivery is to serve as a link
									between our customers and restaurants using our Apps (
									<span className="font-weight-bold">
										“Partner Restaurants”
									</span>
									) by allowing customers to order products for delivery (
									<span className="font-weight-bold">“Delivery”</span>) to their
									homes or offices after paying for the products and applicable
									delivery fee (if any) online (our{" "}
									<span className="font-weight-bold">“Services”</span>). Once an
									order is made through our Apps to a Partner Restaurant, we
									manage the order process and have your products delivered to
									your address indicated at the time of ordering. The delivery
									is done by our couriers (
									<span className="font-weight-bold">“Godlives Couriers”</span>)
									or delivery partners (
									<span className="font-weight-bold">“Delivery Partners”</span>
									).
								</p>
							</div>

							<div className="mb-4" >
								<h3>3. About the Services</h3>
								<p>
									Godlives manages the order made to our Partner Restaurants
									through our App and administers your payment to Godlives
									Partner Restaurants selling the Products to you. Godlives
									Couriers or Delivery Partners pick up the food from the
									restaurants and deliver to the address provided at the time of
									ordering the Product(s), unless you decide to pick up at the
									Partner Restaurant (
									<span className="font-weight-bold">“Pick-Up”</span>).
								</p>

								<p>
									The order is successfully placed after the customer has paid
									for the Product(s) and applicable fee (if any) in full. No
									payment is made to Couriers or Delivery Partners when the
									Products are delivered to you. Your Payment is made directly
									to Godlives and subsequently disbursed to Godlives Partner
									Restaurants. Our Partner Restaurants are certified by the
									National Food Agency (Sw. Livsmedelsverket) to handle and
									serve food to the general public.
								</p>
							</div>

							<div className="mb-4" >
								<h4>3.1 Products Offered</h4>
								<p>
									Products (mainly, food and drinks) marketed on the Godlives
									platform are prepared and packaged by our Partner Restaurants.
									Godlives, our couriers and Delivery Partners do not handle or
									take part in the preparation of meals or ingredients ordered
									from our partners, but only carry the Products with insulated
									bags and transported to your delivery address. We therefore
									refer you to the relevant Partner Restaurants for specific
									information about the Products such as ingredients and
									allergens. Food from our Partner Restaurants are, unless
									otherwise stated, meant to be consumed immediately upon
									delivery. Godlives and Godlives Partner Restaurants shall
									therefore not be responsible for the quality of the food if
									eaten later.
								</p>
							</div>

							<div className="mb-4" >
								<h4>3.2 Service Availability</h4>
								<p>
									Godlives Platform endeavors to connect you to our Partner
									Restaurants that are close to you in order to ensure that the
									Products are delivered to you within the shortest time
									possible. The Apps allow you to order from a prescribed
									delivery area and at a distance within range (currently 8km
									radius). The delivery area and distance may change at any time
									depending on the demand on our Services.
								</p>

								<p>
									Godlives Platform operations are between 9am – 11pm everyday
									(Monday – Sunday). However, Partner Restaurants decide their
									own operating hours which are displayed on the Apps. This
									means that the availability of our Services depends on the
									Partner Restaurants in your area.
								</p>

								<p>
									We will notify you of unavailability of our Services if you
									try to order outside the stipulated time or delivery area, or
									for any other reasons.
								</p>
							</div>

							<div className="mb-4" >
								<h4>3.3 Orders and Delivery</h4>
								<p>
									Orders are placed on our Apps by following the prompts and
									selecting the products you wish to order. You have three (3)
									options to choose from; whether you want the products
									delivered to you as soon as possible (
									<span className="font-weight-bold">“ASAP”</span> delivery),
									pick up the products yourself at the Partner Restaurant (
									<span className="font-weight-bold">“Pick-Up”</span>) or you
									want the products delivered later at a specified time (
									<span className="font-weight-bold">“Advanced”</span>{" "}
									delivery). Please take note that you can only make an Advance
									order a maximum of 3 days. When you place an order, it has to
									be accepted by our Partner Restaurant before it is confirmed.
									A notification is sent to you on the App when the order is
									accepted, and you can begin to track the order. You are
									responsible for ensuring full payment of the Products you
									order and any applicable fee. Please contact our Partner
									Restaurant you wish to order from if you have an allergy.
									Godlives cannot guarantee that any of the Products sold by our
									Partners are free of allergens.
								</p>

								<p>
									An estimated time of delivery will be shown on the App when
									ordering for both ASAP and Advanced deliveries. The time for
									Pick-Up is also shown when ordering. You must therefore be
									available to receive the order at the specified time. The
									couriers will contact you through the messaging device on the
									App or by calling if you are not available to receive your
									order and can only wait for Seven (7) minutes at your delivery
									address. After the Seven (7) minutes, your order will be left
									at the delivery location and neither Godlives nor our partners
									will be responsible for your Product(s). You will not receive
									any refund from either Godlives or the Partner Restaurant if
									you fail to show up at the delivery address or if you give a
									wrong address. The risk of the Products passes to you after
									delivering to your specified location. Godlives couriers and
									Delivery Partners cannot be held liable for any damage or
									mishap that occurs after the delivery.
								</p>

								<p>
									You can change the delivery address after you have placed your
									order. However, the new address must be within our current
									delivery distance of 8km and the change must be done before
									the courier receives the order from the restaurant for
									delivery.
								</p>

								<p>
									If we cannot change the delivery address, you have the option
									to cancel the order, but if food preparation has started you
									will be charged the full price for the Product.
								</p>

								<p>
									If you have already paid for an order but our Partner
									Restaurant is unable to provide the product(s), the order will
									be cancelled, and the full amount refunded to you.
								</p>
							</div>

							<div className="mb-4" >
								<h3>4. Price, Payment and Offers</h3>
								<h4>4.1 Price</h4>
								<p>
									The prices for Products and for applicable delivery fee are
									displayed on the platform. Prices and change of price of
									Products on the platform are at the discretion of our Partner
									Restaurants. Godlives offers free delivery currently and
									reserves the right to review this offer at any time. Updated
									prices and delivery fee will always be shown on the platform
									when ordering. Please make sure you read the latest version of
									the terms and prices before ordering.
								</p>
								<p>
									No changes will affect existing confirmed orders, unless there
									is a pricing mistake. If there is a pricing mistake we will
									notify you as soon as we can, and you will have the choice of
									confirming the order at the original price or cancelling the
									order without charge and with a full refund of any money
									already paid.
								</p>
							</div>

							<div className="mb-4" >
								<h4>4.2 Payment</h4>
								<p>
									Payment for all Products and delivery services can be made on
									our Apps by credit or debit card, or other payment method made
									available by Godlives including Swish. Godlives receives
									payment of all Products on behalf of our Partner Restaurants
									and subsequently disburses to them. As a result, a fixed
									service fee of 5 SEK is charged per each order.
								</p>
								<p>
									Your payment to us therefore fulfills your obligation to pay
									for Products to the Partner Restaurants you are ordering from.
									Please note that no payment of Product(s) or Services is done
									outside the use of the Apps.
								</p>
							</div>

							<div className="mb-4" >
								<h4>4.3 Offers</h4>
								<p>
									Godlives currently offers free delivery services. This simply
									means that there is no delivery fee charged when you make an
									order on our Platform. We reserve the right to review this
									offer at any time but will inform customers of this update on
									the Platform. Our Partner Restaurants sometimes give special
									offers on some of the Products they sell which can be seen on
									our Platform. These offers and changes are at the discretion
									of the Partner Restaurants.
								</p>
							</div>
							<div className="mb-4" >
								<h3>5. Your obligation as a customer</h3>
								<p>
									By accepting these Terms, you confirm that you are at least 18
									years old and that you agree to be bound by these Terms. You
									are also responsible for ensuring that the information you
									provide when registering your user account with Godlives is
									correct. You agree to keep your login credentials and other
									personal information confidential and secure and not to share
									such information with anyone else. If another person uses your
									personal information to access your account, you will be
									responsible to pay for any Product(s) they order, and we are
									not responsible for any losses you suffer.
								</p>
								<p>
									You may close your account at any time by requesting to do so
									in your account section of our Apps or contacting us using the
									contact details above. We may suspend your access to your
									account, or close it permanently, if we believe that your
									account has been used by someone else. We may also close your
									account if in our opinion you are abusing our Service (for
									example, by applying for refunds or credit to which we do not
									consider you are entitled, making repeated unreasonable
									complaints or mistreating our staff).
								</p>
								<p>
									You may not use any Godlives Delivery trademarks or brands
									beyond what is required to use the Platform or the Services.
								</p>
							</div>

							<div className="mb-4" >
								<h3>6. Data Processing and Protection</h3>
								<p>
									We process your personal data in accordance with our{" "}
									<a
										href="https://godlivesdelivery.se/privacy-policy/"
										target="_blank"
										rel="noopener noreferrer"
									>
										Privacy Policy
									</a>{" "}
									and in accordance with{" "}
									<a
										href="https://www.eugdpr.org/"
										target="_blank"
										rel="noopener noreferrer"
									>
										General Data Protection Regulations (GDPR)
									</a>{" "}
									.
								</p>
							</div>

							<div className="mb-4" >
								<h3>7. Complaints and Feedback</h3>
								<p>
									Despite our best efforts to provide satisfactory Services to
									our customers there could be a challenge, either coming from
									us, our Partner Restaurants or Delivery Partners. If you have
									difficulties using our Platform or your Product delivered to
									you is not to your satisfaction, please contact us immediately
									through our Support Service ({" "}
									<a href="mailto:support@godlivesdelivery.se">
										support@godlivesdelivery.se
									</a>{" "}
									) or by using the contact information provided on our website.
									We will try to resolve the issue in accordance with the
									applicable consumer protection legislation as soon as we
									receive your complaint. Godlives Delivery reserves the right
									to determine what is considered an appropriate solution in
									each case.
								</p>
							</div>

							<div className="mb-4" >
								<h3>8. Cancellation and Refunds</h3>
								<p>
									You may cancel an order without charge at any time before our
									Partner Restaurant has started preparing the food. You can
									easily do this by contacting us immediately, via the call
									Service on our App. If the Partner Restaurant confirms that
									preparation of the order has not started, we will refund your
									full payment (excluding any discount or offer that was applied
									to the order). If you cancel any order after preparation of
									the order has started, you will be charged the full price for
									the Product(s). You may usually receive notification that an
									order has been cancelled in all situations.
								</p>
								<p>
									You will not be charged for any orders cancelled by us or the
									Partner Restaurant, and we will reimburse you for any payment
									already made using the same method you used to pay for your
									order. Refunds will be made within two (2) weeks from the day
									the cancellation occurred.
								</p>
							</div>

							<div className="mb-4" >
								<h3>9. Termination or Suspension of Services</h3>
								<p>
									Godlives reserves the right to terminate or suspend the use of
									our Services or Platform by customers who violate these Terms.
									Termination or Suspension may usually be communicated to
									customers through a text message or an email.
								</p>
							</div>

							<div className="mb-4" >
								<h3>10. Customer Support Service</h3>
								<p>
									We try to offer excellent customer service to make you happy.
									You can direct your complaints, questions and suggestions
									through our Support Center accessible on the Godlives
									Platform. Technical issues or difficulties must be
									communicated through our Support Center on the Platform or by
									email at{" "}
									<a href="mailto:support@godlivesdelivery.se">
										support@godlivesdelivery.se
									</a>{" "}
									.
								</p>

								<p>
									Alternatively, you can contact us through the call service on
									the Platform. For general enquiries about our Services, you
									can contact us by email at info@godlivesdelivery.se. More
									information about our Services and the Platform can be seen on
									our website{" "}
									<a
										href="https://www.godlivesdelivery.se/"
										target="_blank"
										rel="noopener noreferrer"
									>
										www.godlivesdelivery.se
									</a>{" "}
									.
								</p>
							</div>

							<div className="mb-4" >
								<h3>11. Intellectual Property Rights</h3>
								<p>
									We are the owner of all intellectual property rights regarding
									the information, software and documentation on our website and
									Platform. All these works are protected by copyright laws and
									treaties worldwide, and all such rights are reserved.
								</p>
								<p>
									You may not copy, reproduce, republish, download, transmit or
									otherwise reuse any content on our website and Platform in any
									way except for the purpose of ordering Products or for
									non-commercial purposes.
								</p>
							</div>

							<div className="mb-4" >
								<h3>12. Our Liability</h3>
								<p>
									Godlives will not be held liable for any defects (such as
									allergens or health issues) arising from the meals sold to our
									customers by our Partner restaurants. It is therefore the sole
									responsibility of our Partner Restaurants to deal with issues
									of liability relating to the Products they sell to customers
									through Godlives Platform. We will not be responsible for any
									errors or omissions in relation to content or technical
									problems you may experience with our Application or website
									when accessing our Services. If we are informed of any
									inaccuracies on our Platform or in our Services we will
									attempt to correct this as soon as possible.
								</p>

								<p>
									We exclude all liability in accordance with relevant law, for
									loss or damage which you or any third party may incur in
									connection with our Services, and any website linked to our
									site and any materials posted on it. This does not affect our
									liability arising from our negligence, or our liability for
									fraudulent misrepresentation or misrepresentation as to a
									fundamental matter, or any other liability which cannot be
									excluded or limited under applicable law.
								</p>
							</div>

							<div className="mb-4" >
								<h3>13. Other Links and Resources on our Website</h3>
								<p>
									Resources provided by third parties and links to other sites
									shown on our website are provided for your information only.
									We have no control over the contents of those sites or
									resources, and therefore accept no responsibility for their
									use.
								</p>
							</div>

							<div className="mb-4" >
								<h3>14. Other Terms</h3>
								<h4>14.1</h4>
								<p>
									Godlives reserves the right to review these Terms at any time
									in order to serve our customers better. Subsequent changes
									made in the Terms will be binding on our customers. You are
									therefore advised to always check the latest version of the
									Terms before using our Services.
								</p>
							</div>

							<div className="mb-4" >
								<h4>14.2</h4>
								<p>
									Godlives may transfer its rights and responsibilities under
									these Terms to a third party when necessary.
								</p>
							</div>

							<div className="mb-4" >
								<h4>14.3</h4>
								<p>
									Godlives reserves the right to make changes regarding the
									name, type and ownership of the Company at any time and shall
									inform our customers of such major changes.
								</p>
							</div>

							<div className="mb-4" >
								<h3>15. Dispute resolution</h3>
								<p>
									These Terms shall be subjected to the interpretation of
									Swedish law. If there is any dispute between Godlives and a
									customer, the parties shall first endeavor to resolve the
									dispute by agreement. If the parties cannot agree, the dispute
									shall be determined by the National Board for Consumer
									Disputes (Sw. Allmänna reklamationsnämnden) or, ultimately, by
									a court of law
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</div>
	);
};

export default TermsAndConditions;
