import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Row, Col, Container, Spinner, Button } from "react-bootstrap";
import { submitForm } from "../../redux/actions/contactFormActions";

const ContactFlex = styled.div`
	margin-top: 2rem;
	margin-bottom: 4rem;
	display: flex;
	justify-content: space-between;
	text-align: center;

	@media (max-width: 500px) {
		flex-direction: column;
	}
`;

const ContactForm = styled.div`
	margin: 0 auto;
	max-width: 500px;
`;

const Contact = ({
	submitForm,
	contact: { submitFormSuccess, submitFormLoading, error },
}) => {
	const [formValues, setFormValues] = useState({
		name: "",
		email: "",
		message: "",
	});
	const [disableSubmit, setDisableSubmit] = useState(false);
	const history = useHistory();
	const { name, email, message } = formValues;

	const handleChange = (e) => {
		setFormValues({
			...formValues,
			[e.target.name]: e.target.value,
		});
	};
	const handleFormSubmit = (e) => {
		e.preventDefault();
		setDisableSubmit(true);
		submitForm(formValues);
	};
	const handleGoHome = () => {
		history.push("/");
	};
	return (
		<section className="pt-5 pb-5">
			<Helmet>
				<title>GodLives Delivery | Contact Us</title>
			</Helmet>
			<Container>
				<Row>
					<Col sm={12} md={8} className="mx-auto">
						<h1 className="mb-4 font-weight-bold text-center">CONTACT US</h1>

						<ContactFlex>
							<div>
								<h4>Address</h4>
								<p>
									Alfred Nobel's Alle 27 <br /> 14152 Huddinge <br /> Stockholm
								</p>
							</div>

							<div>
								<h4>Phone</h4>
								<p>
									<a href="tel:+08-21 58 21">08-21 58 21</a>
								</p>
							</div>

							<div>
								<h4>Email</h4>
								<p>
									<a href="mailto:info@godlivesdelivery.se">
										info@godlivesdelivery.se
									</a>
								</p>
							</div>
						</ContactFlex>

						<h2 className="mb-2 font-weight-bold text-center">DROP A LINE</h2>
						<h4 className="mb-5 text-center">Get In Touch</h4>

						<ContactForm>
							{submitFormSuccess ? (
								<>
									<h4 className="text-center">
										Thank You. Your response has been received!
									</h4>
									<Button
										className="text-center d-block mx-auto mt-3"
										onClick={handleGoHome}
									>
										Go Home
									</Button>
								</>
							) : submitFormLoading ? (
								<Spinner
									className="text-center d-block mx-auto"
									animation="border"
									role="status"
								>
									<span className="sr-only">Loading...</span>
								</Spinner>
							) : error ? (
								<h4 className="text-center text-danger">
									Something went wrong. Please refresh and try again
								</h4>
							) : (
								<form onSubmit={handleFormSubmit}>
									<div className="form-row">
										<div className="form-group col-md-6">
											<label htmlFor="name">Your Name</label>
											<input
												type="text"
												className="form-control"
												id="name"
												name="name"
												placeholder="Your name"
												required
												onChange={handleChange}
												value={name}
											/>
										</div>
										<div className="form-group col-md-6">
											<label htmlFor="email">Your Email</label>
											<input
												type="email"
												className="form-control"
												id="email"
												name="email"
												placeholder="Your Email"
												required
												onChange={handleChange}
												value={email}
											/>
										</div>
										<div className="form-group col-md-12">
											<label htmlFor="message">Your Message</label>
											<textarea
												className="form-control"
												id="message"
												rows="5"
												name="message"
												required
												onChange={handleChange}
												value={message}
											></textarea>
										</div>
									</div>
									<button
										type="submit"
										className="d-block ml-auto btn btn-primary"
										disabled={disableSubmit ? true : false}
									>
										Send Message
									</button>
								</form>
							)}
						</ContactForm>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

const mapStateToProps = (state) => ({
	contact: state.contactForm,
});

const mapDispatchToProps = {
	submitForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
