import { combineReducers } from "redux";
import dummyReducer from "./dummyReducer";
import contactFormReducer from "./contactFormReducer";
import authReducer from "./authReducer";

export default combineReducers({
	dummy: dummyReducer,
	contactForm: contactFormReducer,
	auth: authReducer,
});
