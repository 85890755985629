import {
	SUBMIT_FORM_SUCCESS,
	SET_LOADING,
	SUBMIT_FORM_FAILURE,
} from "../types/contactForm";

const initialState = {
	submitFormSuccess: false,
	submitFormLoading: false,
	error: null,
};

const contactFormReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SUBMIT_FORM_SUCCESS:
			return {
				...state,
				submitFormSuccess: true,
				submitFormLoading: false,
				err: null,
			};
		case SUBMIT_FORM_FAILURE:
			return {
				...state,
				submitFormLoading: false,
				submitFormSuccess: false,
				err: payload,
			};
		case SET_LOADING:
			return {
				...state,
				submitFormLoading: true,
				err: null,
			};
		default:
			return state;
	}
};

export default contactFormReducer;
