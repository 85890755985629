import React from "react";
import { Helmet } from "react-helmet";
import { Row, Col, Container } from "react-bootstrap";

const PrivacyPolicy = () => {
	return (
		<div>
			<section className="pt-5 pb-5 ">
				<Helmet>
					<title>GodLives Delivery | Privacy Policy</title>
				</Helmet>
				<Container>
					<Row>
						<Col>
							<h1 className="mb-5 font-weight-bold text-center ">
								Privacy Policy
							</h1>

							<div className="mb-4">
								<h3>INTRODUCTION</h3>
								<p>
									Godlives Delivery (website url address:{" "}
									<a
										href="https://godlivesdelivery.se"
										target="_blank"
										rel="noopener noreferrer"
									>
										https://godlivesdelivery.se
									</a>
									) appreciates your business and trust. We are Sweden based
									company (Org no: 790821-1555) providing food delivery services
									for your convenience. Please read this Privacy Policy,
									providing consent to both documents in order to have
									permission to use our services.
								</p>

								<p>
									Please read these Terms carefully before creating an account
									or using our Apps. If you have any questions relating to these
									Terms please contact{" "}
									<a href="mailto:info@godlivesdelivery.se">
										info@godlivesdelivery.se
									</a>{" "}
									. By creating an account with Godlives or by using the Apps
									and/or Services specified below you confirm that you have read
									and accept these Terms.
								</p>
							</div>

							<div className="mb-4">
								<h3>2. DATA COLLECTED</h3>
								<h4>REGISTRATION DATA</h4>
								<p>
									If you register on our website, we store your chosen username
									and your email address and any additional personal information
									added to your user profile. You can see, edit, or delete your
									personal information at any time (except changing your
									username).
								</p>

								<h4 className="mt-4">COMMENTS</h4>
								<p>
									When you leave comments on the website we collect the data
									shown in the comments form, and also the IP address and
									browser user agent string to help spam detection. We treat
									comments anonymously.
								</p>

								<h4 className="mt-4">CONTACT FORM</h4>
								<p>
									Information submitted through the contact form on our site is
									sent to our company email hosted by us.
								</p>

								<p>
									These submissions are only kept for customer service purposes
									they are never used for marketing purposes or shared with
									third parties.
								</p>

								<h4 className="mt-4">GOOGLE ANALYTICS</h4>
								<p>
									We use Google Analytics on our site for anonymous reporting of
									site usage. So, no personalized data is stored. If you would
									like to opt-out of Google Analytics monitoring your behavior
									on our website please use this link:{" "}
									<a
										href="https://tools.google.com/dlpage/gaoptout/"
										target="_blank"
										rel="noopener noreferrer"
									>
										Google Analytics Opt-out.
									</a>{" "}
								</p>

								<h4 className="mt-4">CASES FOR USING THE PERSONAL DATA</h4>
								<p className="font-weight-bold">
									We use your personal information in the following cases:
								</p>
								<ul>
									<li>
										Verification/identification of the user during website
										usage;
									</li>
									<li>Providing Technical Assistance;</li>
									<li>
										Sending updates to our users with important information to
										inform about news/changes;
									</li>
									<li>
										Checking the accounts’ activity in order to prevent
										fraudulent transactions and ensure the security
									</li>
									<li>over our customers’ personal information;</li>
									<li>
										Customize the website to make your experience more personal
										and engaging;
									</li>
									<li>
										Guarantee overall performance and administrative functions
										run smoothly.
									</li>
								</ul>
							</div>

							<div className="mb-4">
								<h3>3. SECURITY MEASURES</h3>
								<p>
									We use the SSL/HTTPS protocol throughout our site. This
									encrypts our user communications with the servers so that
									personal identifiable information is not captured/hijacked by
									third parties without authorization.
								</p>

								<p>
									In case of a data breach, system administrators will
									immediately take all needed steps to ensure system integrity,
									will contact affected users and will attempt to reset
									passwords if needed.
								</p>
							</div>

							<div className="mb-4">
								<h3>4. COOKIES</h3>
								<p>
									This site uses cookies – small text files that are placed on
									your machine to help the site provide a better user
									experience. In general, cookies are used to retain user
									preferences, store information for things like shopping carts,
									and provide anonymized tracking data to third party
									applications like Google Analytics. Cookies generally exist to
									make your browsing experience better. However, you may prefer
									to disable cookies on this site and on others. The most
									effective way to do this is to disable cookies in your
									browser. We suggest consulting the help section of your
									browser.
								</p>

								<h4 className="mt-4">NECESSARY COOKIES (ALL SITE VISITORS)</h4>
								<ul>
									<li>
										<span className="font-weight-bold">cfduid:</span> Is used
										for our CDN CloudFlare to identify individual clients behind
										a shared IP address and apply security settings on a
										per-client basis. See more information on privacy here:
										CloudFlare Privacy Policy.
									</li>
								</ul>
							</div>

							<div className="mb-4">
								<h3>5. EMBEDDED CONTENT</h3>

								<p>
									Pages on this site may include embedded content, like YouTube
									videos, for example. Embedded content from other websites
									behaves in the exact same way as if you visited the other
									website.
								</p>

								<p>
									These websites may collect data about you, use cookies, embed
									additional third-party tracking, and monitor your interaction
									with that embedded content, including tracking your
									interaction with the embedded content if you have an account
									and are logged-in to that website. Below you can find a list
									of the services we use:
								</p>

								<h4 className="mt-4">FACEBOOK</h4>
								<p>
									The Facebook page plugin is used to display our Facebook
									timeline on our site. Facebook has its own cookie and privacy
									policies over which we have no control. There is no
									installation of cookies from Facebook and your IP is not sent
									to a Facebook server until you consent to it. See their
									privacy policy here:{" "}
									<a
										href="https://www.facebook.com/privacy/"
										target="_blank"
										rel="noopener noreferrer"
									>
										Facebook Privacy Policy
									</a>{" "}
									.
								</p>

								<h4 className="mt-4">TWITTER</h4>
								<p>
									We use the Twitter API to display our tweets timeline on our
									site. Twitter has its own cookie and privacy policies over
									which we have no control. Your IP is not sent to a Twitter
									server until you consent to it. See their privacy policy here:{" "}
									<a
										href="https://twitter.com/privacy"
										target="_blank"
										rel="noopener noreferrer"
									>
										Twitter Privacy Policy
									</a>{" "}
									.
								</p>

								<h4 className="mt-4">YOUTUBE</h4>
								<p>
									We use YouTube videos embedded on our site. YouTube has its
									own cookie and privacy policies over which we have no control.
									There is no installation of cookies from YouTube and your IP
									is not sent to a YouTube server until you consent to it. See
									their privacy policy here:{" "}
									<a
										href="https://www.youtube.com/static?template=privacy_guidelines"
										target="_blank"
										rel="noopener noreferrer"
									>
										YouTube Privacy Policy
									</a>{" "}
									.
								</p>

								<h4 className="mt-4">CONSENT CHOICE</h4>
								<p>
									We provide you with the choice to accept this or not, we
									prompt consent boxes for all embedded content, and no data is
									transferred before you consented to it.
								</p>
								<p>
									The checkboxes below show you all embeds you have consented to
									so far. You can opt-out any time by un-checking them and
									clicking the update button.
								</p>

								<ul>
									<li>YouTube</li>
									<li>Facebook</li>
									<li>Twitter</li>
								</ul>
							</div>

							<div className="mb-4">
								<h3>6. WHO HAS ACCESS TO YOUR DATA</h3>
								<p>
									If you are not a registered client for our site, there is no
									personal information we can retain or view regarding yourself.
								</p>
								<p>
									If you are a client with a registered account, your personal
									information can be accessed by:
								</p>

								<ul>
									<li>Our system administrators</li>
									<li>
										Our supporters when they (in order to provide support) need
										to get the information about the client accounts and access.
									</li>
								</ul>
							</div>

							<div className="mb-4">
								<h3>7. THIRD PARTY ACCESS TO YOUR DATA</h3>
								<p>
									We don’t share your data with third-parties in a way as to
									reveal any of your personal information like email, name, etc.
								</p>
							</div>

							<div className="mb-4">
								<h3>8. HOW LONG WE RETAIN YOUR DATA</h3>
								<p>
									If you register on our website, the information you provide is
									stored. You can see, edit, or delete your personal information
									at any time (except changing your username).
								</p>
							</div>

							<div className="mb-4">
								<h3>9. YOUR DATA RIGHTS</h3>
								<h4>GENERAL RIGHTS</h4>
								<p>
									If you have a registered account on this website, you can
									request an exported file of the personal data we retain,
									including any additional data you have provided to us.
								</p>
								<p>
									You can also request that we erase any of the personal data we
									have stored. This does not include any data we are obliged to
									keep for administrative, legal, or security purposes. In
									short, we cannot erase data that is vital to you being an
									active customer (i.e. basic account information like an email
									address).
								</p>
								<p>
									If you wish that all of your data is erased, we will no longer
									be able to offer any support or other product-related services
									to you.
								</p>

								<h4 className="mt-4">GDPR RIGHTS</h4>
								<p>
									Your privacy is critically important to us. Going forward with
									the GDPR we aim to support the GDPR standard. Godlives
									Delivery permits residents of the European Union to use its
									Service. Therefore, it is the intent of Godlives Delivery to
									comply with the European General Data Protection Regulation.
									For more details please see here:{" "}
									<a
										href="https://www.eugdpr.org/"
										target="_blank"
										rel="noopener noreferrer"
									>
										{" "}
										EU GDPR Information Portal.
									</a>{" "}
								</p>
							</div>

							<div className="mb-4">
								<h3>10. THIRD PARTY WEBSITES</h3>
								<p>
									Godlives Delivery may post links to third party websites on
									this website. These third party websites are not screened for
									privacy or security compliance by Godlives Delivery, and you
									release us from any liability for the conduct of these third
									party websites.
								</p>
								<p>
									All social media sharing links, either displayed as text links
									or social media icons do not connect you to any of the
									associated third parties, unless you explicitly click on them.
								</p>
								<p>
									Please be aware that this Privacy Policy, and any other
									policies in place, in addition to any amendments, does not
									create rights enforceable by third parties or require
									disclosure of any personal information relating to members of
									the Service or Site. Godlives Delivery bears no responsibility
									for the information collected or used by any advertiser or
									third party website. Please review the privacy policy and
									terms of service for each site you visit through third party
									links.
								</p>
							</div>

							<div className="mb-4">
								<h3>11. RELEASE OF YOUR DATA FOR LEGAL PURPOSES</h3>
								<p>
									At times it may become necessary or desirable to Godlives
									Delivery, for legal purposes, to release your information in
									response to a request from a government agency. You agree that
									we may disclose your information to a third party where we
									believe, in good faith, that it is desirable to do so for the
									purposes of a civil action, criminal investigation, or other
									legal matter. In the event that we receive a subpoena
									affecting your privacy, we may elect to notify you to give you
									an opportunity to file a motion to quash the subpoena, or we
									may attempt to quash it ourselves, but we are not obligated to
									do either. We may also proactively report you, and release
									your information to, third parties where we believe that it is
									prudent to do so for legal reasons, such as our belief that
									you have engaged in fraudulent activities. You release us from
									any damages that may arise from or relate to the release of
									your information to a request from law enforcement agencies or
									private litigants.
								</p>
								<p>
									Any passing on of personal data for legal purposes will only
									be done in compliance with laws of the country you reside in.
								</p>
							</div>

							<div className="mb-4">
								<h3>12. AMENDMENTS</h3>
								<p>
									We may amend this Privacy Policy from time to time. When we
									amend this Privacy Policy, we will update this page
									accordingly and require you to accept the amendments in order
									to be permitted to continue using our services.
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</div>
	);
};

export default PrivacyPolicy;
