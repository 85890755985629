import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Row, Col, Container } from "react-bootstrap";

const AboutFlex = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 1rem;

	& div {
		margin-top: 0rem;
		width: 100%;
	}

	& img {
		display: block;
		width: 60%;
	}

	@media (max-width: 750px) {
		flex-direction: column;
		margin-top: .5rem;

		& img {
			display: none;
		}
	}
`;

const About = () => {
	return (
		<section className="pt-5 pb-5">
			<Helmet>
				<title>GodLives Delivery | About</title>
			</Helmet>
			<Container>
				<Row>
					<Col sm={12} md={8} className="mx-auto">
						<h1 className="mb-3 font-weight-bold text-center ">WELCOME!</h1>
						<h3 className="mb-3 text-center">Who are we?</h3>
						<p>
							Godlives Delivery is a Sweden based company (Org no: 790821-1555)
							that provides food delivery services. The company was established
							on 5th October 2018 in Stockholm, and has undertaken thousands of
							food delivery services with average customer rating of 98%. We
							work to make your life easier by by ensuring that your orders from
							restaurants are delivered to. you in a safely and timely manner
						</p>

						<h2 className="mt-5 text-center">Why People Choose Us</h2>
						<h6 className=" mb-5 text-center">Client's most popular choice</h6>
					</Col>

					<Col sm={12} md={10} className="mx-auto">
						<AboutFlex>
							<div className="mt-2 pt-2">
								<h5>30,000 Restaurant Menus</h5>
								<p>
									We’re working with many restaurants in your city to put food
									all in one place.
								</p>

								<h5>Easy Ordering by Phone</h5>
								<p>
									This allows you to order quickly and easily. Accessible at any
									time.
								</p>

								<h5>Free Mobile Application</h5>
								<p>
									Mobile App allows you to choose restaurant, view price and
									check order.
								</p>
							</div>

							<img alt="phone" src="/img/about-phone.png" />

							<div className="mt-2">
								<h5>Easy Online Ordering</h5>
								<p>
									Once logged in, you can easily navigate around the site to
									complete your order.
								</p>

								<h5>100% Positive Feedbacks</h5>
								<p>
									We care about our customers, that is why we get 100% positive
									feedbacks.
								</p>

								<h5>Fast Guaranteed Delivery</h5>
								<p>
									We take responsibility for making sure that order are
									delivered to you safely.
								</p>
							</div>
						</AboutFlex>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default About;
