import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { NavLink, Link, withRouter } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";
import { logout } from "../../redux/actions/authActions";

const Logo = styled.img`
	width: 6.5rem;

	@media (max-width: 500px) {
		width: 4.5rem;
	}
`;

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isNavExpanded: false,
		};
	}
	setIsNavExpanded = (isNavExpanded) => {
		this.setState({ isNavExpanded: isNavExpanded });
	};
	closeMenu = () => {
		this.setState({ isNavExpanded: false });
	};

	handleClick = (e) => {
		if (this.node.contains(e.target)) {
			// if clicked inside menu do something
		} else {
			// If clicked outside menu, close the navbar.
			this.setState({ isNavExpanded: false });
		}
	};

	componentDidMount() {
		document.addEventListener("click", this.handleClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.handleClick, false);
	}
	render() {
		const {
			auth: { token },
			logout,
		} = this.props;
		return (
			<div ref={(node) => (this.node = node)}>
				<Navbar
					onToggle={this.setIsNavExpanded}
					expanded={this.state.isNavExpanded}
					color="light"
					expand="lg"
					className="navbar-light osahan-nav shadow-sm"
				>
					<Container>
						<Link to="/">
							<Navbar.Brand to="/">
								<Logo src="/img/main-logo.png" alt="" />
							</Navbar.Brand>
						</Link>
						<Navbar.Toggle />
						<Navbar.Collapse id="navbarNavDropdown">
							<Nav activeKey={0} className="ml-auto" onSelect={this.closeMenu}>
								<Nav.Link
									eventKey={0}
									as={NavLink}
									activeclassname="active"
									exact
									to="/"
								>
									Home <span className="sr-only">(current)</span>
								</Nav.Link>
								{/* <Nav.Link eventKey={1} as={NavLink} activeclassname="active" to="/offers">
             				<Icofont icon='sale-discount'/> Offers <Badge variant="danger">New</Badge>
			            </Nav.Link> */}
								<Nav.Link
									eventKey={1}
									as={NavLink}
									activeclassname="active"
									to="/about"
								>
									About
								</Nav.Link>
								{/* <Nav.Link
									eventKey={1}
									as={NavLink}
									activeclassname="active"
									to="/services"
								>
									Our Services
								</Nav.Link> */}
								<Nav.Link
									eventKey={1}
									as={NavLink}
									activeclassname="active"
									to="/contact"
								>
									Contact
								</Nav.Link>
								{!token ? (
									<>
										<Nav.Link
											eventKey={1}
											as={NavLink}
											activeclassname="active"
											to="/register"
										>
											Register
										</Nav.Link>
										<Nav.Link
											eventKey={1}
											as={NavLink}
											activeclassname="active"
											to="/login"
										>
											Login
										</Nav.Link>
									</>
								) : (
									<>
										<Nav.Link
											eventKey={1}
											as={NavLink}
											activeclassname="active"
											to="/myaccount/orders"
										>
											Dashboard
										</Nav.Link>
										<Nav.Link
											eventKey={1}
											as={NavLink}
											activeclassname="active"
											to="#!"
											onClick={(e) => {
												e.preventDefault();
												logout();
												this.props.history.push("/login");
											}}
										>
											Logout
										</Nav.Link>
									</>
								)}
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default withRouter(connect(mapStateToProps, { logout })(Header));
