import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { getUser } from "../../../redux/actions/authActions";
import OrderCard from "../../../components/common/OrderCard";

const Orders = (props) => {
	const {
		getUser,
		auth: { getUserLoading },
	} = props;
	useEffect(() => {
		getUser();
		// eslint-disable-next-line
	}, []);

	return (
		<>
			{getUserLoading ? (
				<Spinner
					className="text-center text-primary d-block mx-auto"
					animation="border"
					role="status"
				>
					<span className="sr-only">Loading...</span>
				</Spinner>
			) : (
				<div className="p-4 bg-white shadow-sm">
					<h4 className="font-weight-bold mt-0 mb-4">Past Orders</h4>
					<OrderCard
						image="/img/3.jpg"
						imageAlt=""
						orderNumber="25102589748"
						orderDate="Mon, Nov 12, 6:26 PM"
						deliveredDate="Mon, Nov 12, 7:18 PM"
						orderTitle="Gus's World Famous Fried Chicken"
						address="730 S Mendenhall Rd, Memphis, TN 38117, USA"
						orderProducts="Veg Masala Roll x 1, Veg Burger x 1, Veg Penne Pasta in Red Sauce x 1"
						orderTotal="$300"
						helpLink="#"
						detailLink="/detail"
					/>
					<OrderCard
						image="/img/4.jpg"
						imageAlt=""
						orderNumber="25102589748"
						orderDate="Mon, Nov 12, 6:26 PM"
						deliveredDate="Mon, Nov 12, 7:18 PM"
						orderTitle="Jimmy's Famous American Tavern"
						address="730 S Mendenhall Rd, Memphis, TN 38117, USA"
						orderProducts="Veg Masala Roll x 1, Veg Burger x 1, Veg Penne Pasta in Red Sauce x 1"
						orderTotal="$300"
						helpLink="#"
						detailLink="/detail"
					/>
					<OrderCard
						image="/img/5.jpg"
						imageAlt=""
						orderNumber="25102589748"
						orderDate="Mon, Nov 12, 6:26 PM"
						deliveredDate="Mon, Nov 12, 7:18 PM"
						orderTitle="The Famous Restaurant"
						address="730 S Mendenhall Rd, Memphis, TN 38117, USA"
						orderProducts="Veg Masala Roll x 1, Veg Burger x 1, Veg Penne Pasta in Red Sauce x 1"
						orderTotal="$300"
						helpLink="#"
						detailLink="/detail"
					/>
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

const mapDispatchToProps = {
	getUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
