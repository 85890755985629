export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const CLEAR_LOGIN_SUCCESS = "CLEAR_LOGIN_SUCCESS"
export const SET_LOGIN_LOADING = "SET_LOGIN_LOADING";
export const LOG_OUT = "LOG_OUT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const CLEAR_REGISTER_SUCCESS = "CLEAR_REGISTER_SUCCESS"
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const SET_REGISTER_LOADING = "SET_REGISTER_LOADING";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_FAILURE = "GET_USER_FAILURE";
export const GET_USER_LOADING = "GET_USER_LOADING";
