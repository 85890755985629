import React from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import setAuthToken from "./utils/setAuthToken";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import Home from "./views/Home/Home";
// import Dashboard from "./views/Dashboard/Dashboard";
import About from "./views/About/About";
import Contact from "./views/Contact/Contact";
import Services from "./views/Services/Services";
import Offers from "./views/Offers/Offers";
import MyAccount from "./views/MyAccount/MyAccount";
import List from "./views/List/List";
import NotFound from "./views/NotFound/NotFound";
import Thanks from "./views/Thanks/Thanks";
import Extra from "./views/Extra/Extra";
import Login from "./views/Login/Login";
import Register from "./views/Register/Register";
import TrackOrder from "./views/TrackOrder/TrackOrder";
import Invoice from "./views/Invoice/Invoice";
import Checkout from "./views/Checkout/Checkout";
import Detail from "./views/Detail/Detail";
import TermsAndConditions from "./views/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./views/PrivacyPolicy/PrivacyPolicy";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "react-select2-wrapper/css/select2.css";
import "./App.css";

if (localStorage.token) {
	setAuthToken(localStorage.token);
}

const App = (props) => {
	const {
		auth: { token, loginSuccess },
	} = props;
	if (
		props.location.pathname === "/login" ||
		props.location.pathname === "/register"
	) {
		if (token && !loginSuccess) {
			return <Redirect to="/myaccount/orders" />;
		}
	}
	return (
		<>
			{/* {props.location.pathname !== "/login" &&
			props.location.pathname !== "/register" ? (
				<Header />
			) : (
				""
			)} */}
			<Header />
			<Switch>
				{/* important */}
				<Route path="/" exact component={Home} />
				<Route path="/terms-conditions" exact component={TermsAndConditions} />
				<Route path="/privacy-policy" exact component={PrivacyPolicy} />
				<Route path="/contact" exact component={Contact} />
				<Route path="/about" exact component={About} />
				<Route path="/services" exact component={Services} />
				{/* <PrivateRoute path="/dashboard" exact component={Dashboard} /> */}
				<PrivateRoute path="/myaccount" component={MyAccount} />

				{/* template */}
				<Route path="/offers" exact component={Offers} />
				<Route path="/listing" exact component={List} />
				<Route path="/extra" exact component={Extra} />
				<Route path="/login" exact component={Login} />
				<Route path="/register" exact component={Register} />
				<Route path="/track-order" exact component={TrackOrder} />
				<Route path="/invoice" exact component={Invoice} />
				<Route path="/checkout" exact component={Checkout} />
				<Route path="/thanks" exact component={Thanks} />
				<Route path="/detail" exact component={Detail} />

				{/* not found */}
				<Route exact component={NotFound} />
			</Switch>
			<NotificationContainer />
			{/* {props.location.pathname !== "/login" &&
			props.location.pathname !== "/register" ? (
				<Footer />
			) : (
				""
			)} */}
			<Footer />
		</>
	);
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps)(App);
