import axios from "axios";
import {
	SUBMIT_FORM_SUCCESS,
	SET_LOADING,
	SUBMIT_FORM_FAILURE,
} from "../types/contactForm";
import asyncErrorStatus from "../../utils/asyncErrorStatus";
import { baseUrl } from "../../utils/constants";

export const submitForm = (formDetails) => async (dispatch) => {
	try {
		setSubmitFormLoading();
		await axios.post(`${baseUrl}/api/contact/send`, formDetails);
		dispatch({
			type: SUBMIT_FORM_SUCCESS,
		});
	} catch (err) {
		asyncErrorStatus(
			dispatch,
			"All fields are required",
			err,
			SUBMIT_FORM_FAILURE
		);
	}
};

export const setSubmitFormLoading = () => {
	return {
		type: SET_LOADING,
	};
};
