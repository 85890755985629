import React, { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Container, Form, Button, Spinner } from "react-bootstrap";
import FontAwesome from "../../components/common/FontAwesome";
import {
	loginAction,
	clearErrors,
	clearLoginSuccess,
} from "../../redux/actions/authActions";

const Login = (props) => {
	const {
		loginAction,
		clearErrors,
		clearLoginSuccess,
		auth: { loginLoading, loginSuccess, error, isAuthenticated },
	} = props;
	const history = useHistory();

	const [formValues, setFormValues] = useState({
		email_phone: "",
		password: "",
	});
	const { email_phone, password } = formValues;
	const [disableSubmit, setDisableSubmit] = useState(true);

	useEffect(() => {
		if (loginSuccess && isAuthenticated) {
			NotificationManager.success("Successfully Logged in!");
			setFormValues({
				email_phone: "",
				password: "",
			});
			setTimeout(() => {
				history.push("/myaccount/orders");
				clearLoginSuccess();
			}, 3500);
		}
	}, [clearLoginSuccess, history, isAuthenticated, loginSuccess]);

	useEffect(() => {
		if (error) {
			NotificationManager.error(error);
			clearErrors();
		}
	}, [error, clearErrors]);

	useEffect(() => {
		if (loginLoading) {
			setDisableSubmit(true);
		} else {
			setDisableSubmit(false);
		}
	}, [loginLoading]);

	const handleFormSubmit = (e) => {
		e.preventDefault();
		loginAction({
			email: email_phone,
			password: password,
		});
	};
	const handleChange = (e) => {
		setFormValues({
			...formValues,
			[e.target.name]: e.target.value,
		});
	};
	return (
		<Container fluid className="bg-white">
			<Helmet>
				<title>GodLives Delivery | Login</title>
			</Helmet>
			<Row className="justify-content-center" >
				{/* <Col md={4} lg={6} className="d-none d-md-flex bg-image"></Col> */}
				<Col mx="auto" md={8} lg={6}>
					<div className="login d-flex align-items-center py-5">
						<Container>
							<Row>
								<Col md={9} lg={8} className="mx-auto pl-5 pr-5">
									<h3 className="login-heading mb-4 text-center">Welcome back!</h3>
									<Form onSubmit={handleFormSubmit}>
										<div className="form-label-group">
											<Form.Control
												name="email_phone"
												type="text"
												id="email_phone"
												placeholder="Email / Phone"
												value={email_phone}
												onChange={handleChange}
												required
											/>
											<Form.Label htmlFor="email_phone">
												Email address or Phone number
											</Form.Label>
										</div>
										<div className="form-label-group">
											<Form.Control
												name="password"
												type="password"
												id="password"
												placeholder="Password"
												value={password}
												onChange={handleChange}
												required
											/>
											<Form.Label htmlFor="password">Password</Form.Label>
										</div>
										<Button
											type="submit"
											disabled={!!disableSubmit}
											className="btn btn-lg btn-block btn-login text-uppercase font-weight-bold mb-2"
										>
											{loginLoading ? (
												<Spinner
													className="text-center d-block mx-auto"
													animation="border"
													role="status"
												>
													<span className="sr-only">Loading...</span>
												</Spinner>
											) : (
												"Sign in"
											)}
										</Button>
										<div className="text-center pt-3">
											Don’t have an account?{" "}
											<Link className="font-weight-bold" to="/register">
												Sign Up
											</Link>
										</div>
									</Form>

									<hr className="my-4" />
									<p className="text-center">LOGIN WITH</p>
									<div className="row">
										<div className="col pl-2">
											<Button className="btn pl-1 pr-1 btn-lg btn-facebook font-weight-normal text-white btn-block text-uppercase">
												<FontAwesome icon="facebook" className="mr-2" />{" "}
											</Button>
										</div>
										<div className="col pr-2">
											<Button className="btn pl-1 pr-1 btn-lg btn-instagram font-weight-normal text-white btn-block text-uppercase">
												<FontAwesome icon="instagram" className="mr-2" />
											</Button>
										</div>
										<div className="col pr-2">
											<Button className="btn pl-1 pr-1 btn-lg btn-twitter font-weight-normal text-white btn-block text-uppercase">
												<FontAwesome icon="twitter" className="mr-2" />
											</Button>
										</div>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

const mapDispatchToProps = {
	loginAction,
	clearErrors,
	clearLoginSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
