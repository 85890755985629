import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel3";
import TopSearch from "../../components/home/TopSearch";
import ProductBox from "../../components/home/ProductBox";
import CardItem from "../../components/common/CardItem";
import SectionHeading from "../../components/common/SectionHeading";
import { GoLocation } from "react-icons/go";
import { BiRestaurant } from "react-icons/bi";
import { GiHamburger } from "react-icons/gi";
import { RiMotorbikeFill } from "react-icons/ri";

class Index extends React.Component {
	render() {
		return (
			<>
				<TopSearch />
				<section className="mt-5 mb-3">
					<Container>
						<Row>
							<Col>
								<h2 className="text-center">Hundreds of Dishes to Order</h2>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="section pt-5 pb-5 bg-white homepage-add-section">
					<Container>
						<Row>
							<Col md={3} xs={6}>
								<ProductBox
									image="img/sushi.jpg"
									imageClass="img-fluid rounded"
									imageAlt="product"
									linkUrl="#"
								/>
							</Col>
							<Col md={3} xs={6}>
								<ProductBox
									image="img/pizza.jpg"
									imageClass="img-fluid rounded"
									imageAlt="product"
									linkUrl="#"
								/>
							</Col>
							<Col md={3} xs={6}>
								<ProductBox
									image="img/burger.jpg"
									imageClass="img-fluid rounded"
									imageAlt="product"
									linkUrl="#"
								/>
							</Col>
							<Col md={3} xs={6}>
								<ProductBox
									image="img/dessert.jpg"
									imageClass="img-fluid rounded"
									imageAlt="product"
									linkUrl="#"
								/>
							</Col>
						</Row>
					</Container>
				</section>

				<section className="section pt-5 pb-5 products-section">
					<Container>
						<SectionHeading
							heading="More than 20,000 Dishes to order"
							subHeading="Welcome to The Biggest Network of Food Ordering and Delivery"
						/>
						<Row>
							<Col md={12}>
								<OwlCarousel
									nav
									loop
									{...options}
									className="owl-carousel-four owl-theme"
								>
									<div className="item">
										<CardItem
											title="Pizza Margherita"
											subTitle="PIZZA, VEGETARIAN"
											imageAlt="Product"
											image="img/margherita.jpg"
											imageClass="img-fluid item-img"
											linkUrl="#"
											promotedVariant="dark"
											rating="3.1 (300+)"
										/>
									</div>
									<div className="item">
										<CardItem
											title="Ravioli with Spinach"
											subTitle="PASTA, VEGETARIAN"
											imageAlt="Product"
											image="img/ravioli.jpg"
											imageClass="img-fluid item-img"
											linkUrl="#"
											rating="3.1 (300+)"
										/>
									</div>
									<div className="item">
										<CardItem
											title="Three-Meat Special Lasagna"
											subTitle="MAET"
											imageAlt="Product"
											image="img/lasagna.jpg"
											imageClass="img-fluid item-img"
											linkUrl="#"
											rating="3.1 (300+)"
										/>
									</div>
									<div className="item">
										<CardItem
											title="California Roll with Black"
											subTitle="FISH"
											imageAlt="Product"
											image="img/california-roll.jpg"
											imageClass="img-fluid item-img"
											linkUrl="#"
											rating="3.1 (300+)"
										/>
									</div>
								</OwlCarousel>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="section pt-5 pb-5 bg-white becomemember-section border-bottom">
					<Container>
						<SectionHeading
							heading="How to Order?"
							subHeading="Follow the steps"
						/>
						<Row>
							<Col xs={12} md={2} lg={3} className="text-center">
								<div className="mx-auto text-center">
									<GoLocation size="4rem" color="black" />
									<h6 className="mt-3">Choose Your Location</h6>
								</div>
							</Col>
							<Col xs={12} md={2} lg={3} className="text-center">
								<div className="mx-auto text-center">
									<BiRestaurant size="4rem" />
									<h6 className="mt-3">Choose Restaurant</h6>
								</div>
							</Col>
							<Col xs={12} md={2} lg={3} className="text-center">
								<div className="mx-auto text-center">
									<GiHamburger size="4rem" />
									<h6 className="mt-3">Make Your Order </h6>
								</div>
							</Col>
							<Col xs={12} md={2} lg={3} className="text-center">
								<div className="mx-auto text-center">
									<RiMotorbikeFill size="4rem" />
									<h6 className="mt-3"> Food is on the way</h6>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			</>
		);
	}
}

const options = {
	responsive: {
		0: {
			items: 1,
		},
		600: {
			items: 2,
		},
		1000: {
			items: 4,
		},
		1200: {
			items: 4,
		},
	},

	lazyLoad: true,
	pagination: false.toString(),
	loop: true,
	dots: false,
	autoPlay: 2000,
	nav: true,
	navText: [
		"<i class='fa fa-chevron-left'></i>",
		"<i class='fa fa-chevron-right'></i>",
	],
};

export default Index;
