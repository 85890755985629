import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { NotificationManager } from "react-notifications";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Container, Form, Button, Spinner } from "react-bootstrap";
import FontAwesome from "../../components/common/FontAwesome";
import {
	registerAction,
	clearErrors,
	clearRegisterSuccess,
} from "../../redux/actions/authActions";

const Register = (props) => {
	const {
		registerAction,
		clearErrors,
		auth: { registerLoading, registerSuccess, error },
	} = props;
	const [formValues, setFormValues] = useState({
		name: "",
		email: "",
		phone_number: "",
		password: "",
		confirm_password: "",
	});
	const { name, email, phone_number, password, confirm_password } = formValues;
	const [disableSubmit, setDisableSubmit] = useState(true);
	const [showPasswordsMatch, setShowPasswordsMatch] = useState(false);
	const history = useHistory();

	useEffect(() => {
		if (
			name &&
			email &&
			phone_number &&
			password &&
			confirm_password &&
			password === confirm_password
		) {
			setDisableSubmit(false);
		} else {
			setDisableSubmit(true);
		}
	}, [confirm_password, email, name, password, phone_number]);

	useEffect(() => {
		if (password === confirm_password) {
			setShowPasswordsMatch(false);
		} else {
			setShowPasswordsMatch(true);
		}
	}, [confirm_password, password]);

	useEffect(() => {
		if (registerLoading) {
			setDisableSubmit(true);
		} else {
			setDisableSubmit(false);
		}
	}, [registerLoading]);

	useEffect(() => {
		if (registerSuccess) {
			NotificationManager.success("Signup successful! You can now login");
			setFormValues({
				name: "",
				email: "",
				password: "",
				confirm_password: "",
				phone_number: "",
			});
			clearRegisterSuccess();
			setTimeout(() => {
				history.push("/login");
			}, 3500);
		}
	}, [history, registerSuccess]);

	useEffect(() => {
		if (error) {
			NotificationManager.error(error);
			clearErrors();
		}
	}, [error, clearErrors]);

	const handleFormSubmit = (e) => {
		e.preventDefault();
		registerAction({
			email: email,
			password: password,
			name: name,
			contactNumber: phone_number,
		});
	};
	const handleChange = (e) => {
		setFormValues({
			...formValues,
			[e.target.name]: e.target.value,
		});
	};
	return (
		<Container fluid className="bg-white">
			<Helmet>
				<title>GodLives Delivery | Register</title>
			</Helmet>
			<Row className="justify-content-center"  >
				{/* <Col md={4} lg={6} className="d-none d-md-flex bg-image"></Col> */}
				<Col md={8} lg={6}>
					<div className="login d-flex align-items-center py-5">
						<Container>
							<Row >
								<Col md={9} lg={8} className="mx-auto pl-5 pr-5">
									<h3 className="login-heading mb-4 text-center ">Create an Account</h3>
									<Form onSubmit={handleFormSubmit}>
										<div className="form-label-group">
											<Form.Control
												required
												name="name"
												type="text"
												id="name"
												placeholder="Full Name"
												value={name}
												onChange={handleChange}
											/>
											<Form.Label htmlFor="name">Full Name</Form.Label>
										</div>
										<div className="form-label-group">
											<Form.Control
												required
												name="email"
												type="email"
												id="email"
												placeholder="Email address"
												value={email}
												onChange={handleChange}
											/>
											<Form.Label htmlFor="email">Email address</Form.Label>
										</div>
										<div className="form-label-group">
											<Form.Control
												required
												name="phone_number"
												type="number"
												id="phone_number"
												placeholder="Phone Number"
												value={phone_number}
												onChange={handleChange}
											/>
											<Form.Label htmlFor="phone_number">
												Phone Number
											</Form.Label>
										</div>
										<div className="form-label-group">
											<Form.Control
												required
												name="password"
												type="password"
												id="inputPassword"
												placeholder="Password"
												value={password}
												onChange={handleChange}
											/>
											<small
												style={{
													display: showPasswordsMatch ? "block" : "none",
												}}
												className="text-danger"
											>
												passwords do not match{" "}
											</small>
											<Form.Label htmlFor="inputPassword">Password</Form.Label>
										</div>
										<div className="form-label-group">
											<Form.Control
												required
												name="confirm_password"
												type="password"
												id="confirm_password"
												placeholder="Confirm your password"
												value={confirm_password}
												onChange={handleChange}
											/>
											<small
												style={{
													display: showPasswordsMatch ? "block" : "none",
												}}
												className="text-danger"
											>
												passwords do not match{" "}
											</small>
											<Form.Label htmlFor="confirm_password">
												Confirm Password
											</Form.Label>
										</div>
										<Form.Group
											style={{
												display: "flex",
												alignItems: "center",
												marginTop: "1.5rem",
											}}
										>
											<Form.Check
												required
												style={{ marginBottom: ".7rem", marginRight: ".5rem" }}
												type="checkbox"
											/>
											<Form.Label htmlFor="agreementCheck">
												I agree to the{" "}
												<Link to="/terms-conditions">Terms and Conditions</Link>
											</Form.Label>
										</Form.Group>
										<Button
											disabled={!!disableSubmit}
											type="submit"
											className="text-white btn btn-lg btn-block text-uppercase font-weight-bold mb-2"
										>
											{registerLoading ? (
												<Spinner
													className="text-center d-block mx-auto"
													animation="border"
													role="status"
												>
													<span className="sr-only">Loading...</span>
												</Spinner>
											) : (
												"SIGN UP"
											)}
										</Button>
										<div className="text-center pt-3">
											Already have an account?{" "}
											<Link className="font-weight-bold" to="/login">
												Sign In
											</Link>
										</div>
									</Form>

									<hr className="my-4" />
									<p className="text-center">SIGNUP WITH</p>
									<div className="row">
										<div className="col pl-2">
											<Button className="btn pl-1 pr-1 btn-lg btn-facebook font-weight-normal text-white btn-block text-uppercase">
												<FontAwesome icon="facebook" className="mr-2" />{" "}
											</Button>
										</div>
										<div className="col pr-2">
											<Button className="btn pl-1 pr-1 btn-lg btn-instagram font-weight-normal text-white btn-block text-uppercase">
												<FontAwesome icon="instagram" className="mr-2" />
											</Button>
										</div>
										<div className="col pr-2">
											<Button className="btn pl-1 pr-1 btn-lg btn-twitter font-weight-normal text-white btn-block text-uppercase">
												<FontAwesome icon="twitter" className="mr-2" />
											</Button>
										</div>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				</Col>
			</Row>
			{/* <NotificationContainer /> */}
		</Container>
	);
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

const mapDispatchToProps = {
	registerAction,
	clearErrors,
	clearRegisterSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
