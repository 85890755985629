const asyncErrorStatus = (dispatch, clientErrorPayload, err, type) => {
	if (err.response) {
		if (err.response.status) {
			const status = err.response.status;
			if (/^4/.test(status)) {
				dispatch({
					type,
					payload: clientErrorPayload,
				});
			} else if (/^5/.test(status)) {
				dispatch({
					type,
					payload: " Something went wrong. Please try again",
				});
			}
		} else {
			return dispatch({
				type,
				payload: "Something went wrong. Please try again.",
			});
		}
	} else {
		return dispatch({
			type,
			payload: "Something went wrong. Please try again.",
		});
	}
};

export default asyncErrorStatus;
