import React from "react";
import styled from "styled-components";
import { Row, Col, Container } from "react-bootstrap";

const MainHeading = styled.h1`
	font-size: 3.5rem;
	@media (max-width: 500px) {
		font-size: 2.5rem;
	}
`;

const SubHeading = styled.h5`
	font-size: 2.5rem;
	@media (max-width: 750px) {
		margin-top: 2rem;
		font-size: 2rem;
	}
`;

class TopSearch extends React.Component {
	render() {
		return (
			<section
				style={{
					backgroundImage: "url(img/slider-home.jpg)",
					backgroundSize: "cover",
					height: "100vh",
					backgroundColor: "transparent",
					color: "white",
				}}
				className="pt-5 pb-5 homepage-search-block position-relative"
			>
				<div className="banner-overlay"></div>
				<Container>
					<Row className=" pt-5 d-flex align-items-center justify-content-center  ">
						<Col className=" pt-5 text-center" md={12}>
							<div className="homepage-search-title">
								<MainHeading className=" mb-2 text-white  font-weight-normal">
									ORDER FOOD DELIVERY
								</MainHeading>
								<SubHeading className="mb-5 text-white font-weight-normal">
									From Your Favorite Restaurants
								</SubHeading>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
}

export default TopSearch;
