import React from "react";
import { Helmet } from "react-helmet";
import { Row, Col, Container } from "react-bootstrap";

const Services = () => {
	return (
		<section className="pt-5 pb-5">
			<Helmet>
				<title>GodLives Delivery | Our Services</title>
			</Helmet>
			<Container>
				<Row>
					<Col>our services</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Services;
