import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { Row, Col, Container, Image } from "react-bootstrap";
import { getUser } from "../../redux/actions/authActions";
import Offers from "./others/Offers";
import Orders from "./others/Orders";
import Favourites from "./others/Favourites";
import Payments from "./others/Payments";
import Addresses from "./others/Addresses";
import EditProfileModal from "../../components/modals/EditProfileModal";

const MyAccount = (props) => {
	const {
		getUser,
		// auth: { getUserLoading },
	} = props;
	useEffect(() => {
		getUser();
		// eslint-disable-next-line
	}, []);

	const [showEditProfile, setShowEditProfile] = useState(false);
	const hideEditProfile = () => {
		setShowEditProfile(false);
	};
	const handleShowEditProfile = () => {
		setShowEditProfile(true);
	};
	return (
		<>
			<EditProfileModal show={showEditProfile} onHide={hideEditProfile} />
			<section className="section pt-4 pb-4 osahan-account-page">
				<Container>
					<Row>
						<Col md={3}>
							<div className="osahan-account-page-left shadow-sm bg-white h-100">
								<div className="border-bottom p-4">
									<div className="osahan-user text-center">
										<div className="osahan-user-media">
											<Image
												className="mb-3 rounded-pill shadow-sm mt-1"
												src="/img/user/4.png"
												alt="gurdeep singh osahan"
											/>
											<div className="osahan-user-media-body">
												<h6 className="mb-2">Gurdeep Singh</h6>
												<p className="mb-1">+91 85680-79956</p>
												<p>iamosahan@gmail.com</p>
												<p className="mb-0 text-black font-weight-bold">
													<Link
														to="#"
														onClick={handleShowEditProfile}
														className="text-primary mr-3"
													>
														<i className="icofont-ui-edit"></i> EDIT
													</Link>
												</p>
											</div>
										</div>
									</div>
								</div>
								<ul className="nav flex-column border-0 pt-4 pl-4 pb-4">
									<li className="nav-item">
										<NavLink
											className="nav-link"
											activeClassName="active"
											exact
											to="/myaccount/orders"
										>
											<i className="icofont-food-cart"></i> Orders
										</NavLink>
									</li>
									<li className="nav-item">
										<NavLink
											className="nav-link"
											activeClassName="active"
											exact
											to="/myaccount/offers"
										>
											<i className="icofont-sale-discount"></i> Offers
										</NavLink>
									</li>
									<li className="nav-item">
										<NavLink
											className="nav-link"
											activeClassName="active"
											exact
											to="/myaccount/favourites"
										>
											<i className="icofont-heart"></i> Favourites
										</NavLink>
									</li>
									<li className="nav-item">
										<NavLink
											className="nav-link"
											activeClassName="active"
											exact
											to="/myaccount/payments"
										>
											<i className="icofont-credit-card"></i> Payments
										</NavLink>
									</li>
									<li className="nav-item">
										<NavLink
											className="nav-link"
											activeClassName="active"
											exact
											to="/myaccount/addresses"
										>
											<i className="icofont-location-pin"></i> Addresses
										</NavLink>
									</li>
								</ul>
							</div>
						</Col>
						<Col md={9}>
							<Switch>
								<Route path="/myaccount/orders" exact component={Orders} />
								<Route path="/myaccount/offers" exact component={Offers} />
								<Route
									path="/myaccount/favourites"
									exact
									component={Favourites}
								/>
								<Route path="/myaccount/payments" exact component={Payments} />
								<Route
									path="/myaccount/addresses"
									exact
									component={Addresses}
								/>
							</Switch>
						</Col>
					</Row>
				</Container>
			</section>
		</>
	);
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

const mapDispatchToProps = {
	getUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
