import {
	LOGIN_FAILURE,
	LOGIN_SUCCESS,
	LOG_OUT,
	REGISTER_FAILURE,
	REGISTER_SUCCESS,
	SET_LOGIN_LOADING,
	SET_REGISTER_LOADING,
	CLEAR_ERRORS,
	CLEAR_REGISTER_SUCCESS,
	CLEAR_LOGIN_SUCCESS,
	GET_USER_FAILURE,
	GET_USER_LOADING,
	GET_USER_SUCCESS,
} from "../types/auth";

const initialState = {
	token: localStorage.token,
	loginLoading: false,
	registerLoading: false,
	loginSuccess: false,
	registerSuccess: false,
	error: null,
	getUserLoading: false,
	getUserSuccess: false,
	user: null,
	isAuthenticated: false,
};

// make all errors more descriptive later

const authReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case LOGIN_SUCCESS:
			return {
				...state,
				loginSuccess: true,
				token: payload,
				loginLoading: false,
				error: null,
				isAuthenticated: true,
			};
		case LOGIN_FAILURE:
			return {
				...state,
				loginSuccess: false,
				loginLoading: false,
				error: payload,
				isAuthenticated: false,
			};
		case SET_LOGIN_LOADING:
			return {
				...state,
				loginLoading: true,
			};
		case CLEAR_LOGIN_SUCCESS:
			return {
				...state,
				loginSuccess: false,
			};
		case REGISTER_SUCCESS:
			return {
				...state,
				registerSuccess: true,
				registerLoading: false,
				error: null,
			};
		case REGISTER_FAILURE:
			return {
				...state,
				registerSuccess: false,
				registerLoading: false,
				error: payload,
				isAuthenticated: false,
			};
		case SET_REGISTER_LOADING:
			return {
				...state,
				registerLoading: true,
			};
		case CLEAR_REGISTER_SUCCESS:
			return {
				...state,
				registerSuccess: false,
			};
		case GET_USER_SUCCESS:
			return {
				...state,
				getUserLoading: false,
				getUserSuccess: true,
				user: payload,
				isAuthenticated: true,
			};
		case GET_USER_LOADING:
			return {
				...state,
				getUserLoading: true,
			};
		case GET_USER_FAILURE:
			return {
				...state,
				getUserLoading: false,
				getUserSuccess: false,
				user: null,
				isAuthenticated: false,
			};
		case CLEAR_ERRORS:
			return {
				...state,
				error: null,
			};
		case LOG_OUT:
			return {
				...state,
				token: null,
				user: null,
				error: null,
				isAuthenticated: false,
				loginSuccess: false,
			};
		default:
			return state;
	}
};

export default authReducer;
